<template>
  <div
    class="max-w py-3 mb-3 mx-2 bg-white border border-gray-200 rounded-lg shadow "
  >
    <div class="flex items-center justify-between">
      <h2
        class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
      >
        {{content?.title}}
      </h2>
      <button
       v-if="eventStore.isOwner"
        @click="toggleEditMode"
        class="py-1.5 px-3 mb-2 text-center bg-white border rounded-md text-gray-800 mr-2"
      >
        Edit
      </button>
    </div>
    <hr class="mb-3" />
    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{content?.firstName}} : </span>
        <span class="text-gray-600">{{ groom.firstName  }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{content?.lastName}} : </span>
        <span class="text-gray-600">{{ groom.lastName  }}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{content?.nickName}} : </span>
        <span class="text-gray-600">{{ groom.nickName  }}</span>
      </p>
    </div>
  </div>
</template>

<script setup>
const eventStore = useEventStore();
const props = defineProps({
  content: { type: Object, default: {}},
  isEdit: { type: Boolean, required: true },
  groom: { type: Object },
});

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};
</script>
