<template>
  <div
    class="max-w py-3 mx-2 mb-3 bg-white border border-gray-200 rounded-lg shadow"
  >
    <div class="flex items-center justify-between">
      <h2
        class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
      >
        {{ content?.title }}
      </h2>
      <div class="flex justify-end items-center px-3">
        <button
          v-if="eventStore.isOwner"
          @click="toggleEditMode"
          class="py-1.5 px-3 mb-2 text-center bg-white border rounded-md text-gray-800"
        >
          Edit
        </button>
      </div>
    </div>

    <hr class="mb-3" />
    <div class="px-3 mb-2">
      <label class="text-gray-600"
        >
        <span class="font-semibold text-gray-800">{{content?.active}} :</span>
        <span v-if="eventActive" class="text-green-700 font-bold">เปิด</span>
        <span v-else class="text-red-700 font-bold">ปิด</span>
      </label>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{content?.location}} : </span>
        <span class="text-gray-600">{{ props.eventLocation}}</span>
      </p>
    </div>

    <div class="px-3 mb-2 w-full">
      <p>
        <span class="font-semibold text-gray-800">{{content?.province}} : </span>
        <span class="text-gray-600">{{ props.eventProvince}}</span>
      </p>
    </div>

    <div class="px-3 mb-2">
      <p>
        <span class="font-semibold text-gray-800">{{content?.eventDate}} : </span>
        <span class="text-gray-600">{{ dayjs(props.eventDate).format("YYYY-MM-DD") }}</span>
      </p>
    </div>

    <div class="px-3">
      <p>
        <span class="font-semibold text-gray-800">{{content?.scheduledEvent}} : </span>
        <span class="text-gray-600">{{ props.eventTimeStart  }}</span>
      </p>
    </div>

    <div class="px-3">
      <p>
        <span class="font-semibold text-gray-800">{{content?.morning}} : </span>
        <span v-if="props.eventTime['0700']" class="text-green-900">
          <i class="fa-solid fa-circle-check"></i>
        </span>
        
        <span v-else class="text-red-600">
          <i class="fa-solid fa-circle-xmark"></i>
        </span>
      </p>
    </div>

    <div class="px-3">

      <p>
        <span class="font-semibold text-gray-800">{{content?.evening}} : </span>
        <span v-if="props.eventTime['1800']" class="text-green-900">
          <i class="fa-solid fa-circle-check"></i>
        </span>
        
        <span v-else class="text-red-600">
          <i class="fa-solid fa-circle-xmark"></i>
        </span>
      </p>
    </div>

    <hr class="my-5"/>


    <div class="px-3 mb-2 space-y-2">
      <p class="text-gray-800 mb-2 font-bold">{{content?.title2}}</p>
      <p>
        <span class="font-semibold text-gray-800">{{content?.lang}} : </span>
        <span class="text-gray-600">{{ displayLang }}</span>
      </p>

      <div>
        <p>
          <span class="font-semibold text-gray-800">{{content?.showRandomWish}} : </span>
          <span class="text-gray-600">{{eventShowRandomWish ? 'เปิด' : 'ปิด'}}</span>
        </p>
        <p class=" text-gray-400 text-sm italic">{{content?.helperRandomText}}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import dayjs from "dayjs";
const eventStore = useEventStore();

const props = defineProps({
  content: { type: Object, default: {}},
  isEdit: { type: Boolean, required: true },
  eventActive: { type: Boolean, required: true },
  eventLang: { type: String, required: 'th' },
  eventShowRandomWish: { type: Boolean, default: true},
  eventLocation: { type: String },
  eventProvince: { type: String },
  eventDate: { type: String, required: true },
  eventTime: { type: String, required: true },
  eventTimeStart: { type: String, required: true },
});

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};

const displayLang = computed(() => props?.eventLang === 'th' ? "ภาษาไทย" : "English")
</script>
